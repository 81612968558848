/* // All fonts to be added here.. */

@font-face {
  font-family: poppins-black;
  src: url('./Poppins/Poppins-Black.ttf');
}

@font-face {
  font-family: poppins-medium;
  src: url('./Poppins/Poppins-Medium.ttf');
}

@font-face {
  font-family: poppins-blackitalic;
  src: url('./Poppins/Poppins-BlackItalic.ttf');
}
@font-face {
  font-family: poppins-bold;
  src: url('./Poppins/Poppins-Bold.ttf');
}

@font-face {
  font-family: poppins-bolditalic;
  src: url('./Poppins/Poppins-BoldItalic.ttf');
}
@font-face {
  font-family: poppins-regular;
  src: url('./Poppins/Poppins-Regular.ttf');
}

