body {
  // background-color: #e5e5e5;
  background-color: #f8f8f8;
  font-family: poppins-regular !important;
}
* {
  font-family: 'Poppins', sans-serif !important;
}

.MuiInputBase-root {
  border-radius: 10px !important;
}

.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root {
  color: #92929d;
}

.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background: none !important;
  color: #012169;
}
.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover {
  background: none;
  color: #012169;
}

// Mui Button
.MuiButton-root {
  border-radius: 10px !important;
}
